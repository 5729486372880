<template>
  <v-layout class="fill-height" column>
    <v-layout class="fill-height">
      <div v-show="leftMenu" class="mr-4" style="width: 400px; height: 100%">
        <v-layout column fill-height>
          <v-card :disabled="editing" class="AOI-menu custom-card-bg-1" height="100%" width="100%">
            <v-layout class="fill-height" column>
              <div style="flex: 1">
                <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
                  <v-icon size="26">icon-chevrons_left</v-icon>
                </v-btn>
                <LayerControl
                  ref="layerControl"
                  :current-layers.sync="layers"
                  comparable
                  map-control
                  removable
                  transparent
                  @changeCompare="show => $refs.map.changeCompare(show)"
                  @changeToSyncMap="status => ($refs.map.mapSync = status)"
                  @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
                  @changeVisibleLeftLayer="(layer, isSync) => $refs.map.changeVisibleLeftLayer(layer.id)"
                  @reloadMap="() => $refs.map.reloadMap()"
                  @removeAllLayer="() => $refs.map.removeAllLayer()"
                  @removeLayer="layerId => $refs.map.removeLayer(layerId)"
                  @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
                  @zoomTo="bbox => $refs.map.submitZoom(bbox)"
                />
              </div>
            </v-layout>
          </v-card>
        </v-layout>
      </div>
      <v-layout class="fill-height" column>
        <div class="pb-4" style="width: 100%;">
          <v-layout class="fill-height">
            <v-row>
              <v-col class="d-flex align-center pt-4" cols="12" md="4">
                <v-select
                  v-model="currentAOI"
                  :disabled="editing"
                  :items="AOIs"
                  :loading="loading"
                  dense
                  hide-details
                  item-text="name"
                  label="Select AOI"
                  outlined
                  placeholder="AOI"
                  return-object
                  @change="getService"
                />
              </v-col>
              <v-col class="d-flex align-center pt-4" cols="12" md="4">
                <v-select
                  v-model="service"
                  :disabled="!currentAOI || editing"
                  :items="services"
                  :loading="loading"
                  dense
                  hide-details
                  item-text="service_name"
                  label="Select service"
                  outlined
                  placeholder="Service"
                  return-object
                  @change="getListResult"
                />
              </v-col>
              <v-col class="d-flex align-center pt-4" cols="12" md="4">
                <v-select
                  v-model="result"
                  :disabled="!service || editing"
                  :items="data"
                  :loading="loading"
                  dense
                  hide-details
                  item-text="date"
                  label="Select date"
                  outlined
                  placeholder="Date"
                  return-object
                  @change="getData"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>
        </div>
        <v-card height="100%" style="position: relative" width="100%">
          <v-btn
            v-if="!leftMenu"
            color="bgContainer"
            fab
            style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
            @click="leftMenu = true"
          >
            <v-icon>icon-layers</v-icon>
          </v-btn>
          <v-btn-toggle v-if="editing" style="position: absolute; top: 12px; left: 60px; z-index: 2">
            <v-btn small width="120" @click="saveFeature">
              <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
              Save
            </v-btn>
            <v-btn small width="120" @click="editing = false">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
          </v-btn-toggle>
          <Map ref="map" :current-layers.sync="layers" :isDraw.sync="editing" />
        </v-card>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import { getOrder } from '@/api/order'
import { getChangeDetectionLayer, getChangeDetectionResult, getFeature, getVector } from '@/api/change-detection-api'
import { updateFeature } from '@/api/qaqc-api'
import AreaConvert from '@/utils/textArea'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import CustomIcon from '@/components/CustomIcon.vue'

export default {
  name: 'Ground-Deformation',
  components: { CustomIcon, LayerControl, Map },
  data() {
    return {
      dataLoading: false,
      loading: false,
      editing: false,
      layers: [],
      editFeature: undefined,
      currentAOI: undefined,
      leftMenu: true,
      services: [],
      service: undefined,
      data: [],
      result: undefined,
      totalData: 0,
      changeData: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs']),
  },
  mounted() {
    this.getListAOI()
  },
  watch: {
    editing(val) {
      if (!val) {
        this.editFeature = undefined
        this.$refs.map.resetDraw()
        this.changeMapMode('slide')
      }
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    async changeMapMode(mode) {
      if (!this.$refs.layerControl && !this.$refs.map) {
        await sleep(100)
        await this.changeMapMode(mode)
      } else {
        await sleep(500)
        this.$refs.layerControl.mapMode = mode
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_group: 'Change Detection' },
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        // this.$refs.cardMap.zoomTo(this.currentAOI.bbox)
        const res = await getOrder({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        this.services = res.data.filter(val => val.service_group === 'Change Detection')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.data = []
        const res = await getChangeDetectionResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            per_page: 'all',
          },
        })
        this.data = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getData() {
      try {
        this.dataLoading = true
        this.changeData = []
        const res = await getVector({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: {
            per_page: 'all',
          },
        })
        this.$refs.map.removeAllLayer()
        await this.getLayers()
        this.changeData = res.data.data
        this.totalData = res.data.total
      } catch (e) {
      } finally {
        this.dataLoading = false
      }
    },
    async getLayers() {
      try {
        this.loading = true
        const res = await getChangeDetectionLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: {},
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.addImageToMap(image)
          } else {
            this.$refs.map.addVectorTiles({
              data: val.tile_info,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              name: val.name,
              id: val.name,
              type: vector.tile_info.styles.type,
            })
          }
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.map.displayVector(
        'RED',
        featureDetail.geojson,
        'line',
        'red',
        'change-id-' + featureDetail.id,
        false,
        'polygon',
      )
      this.$refs.map.submitZoom(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.map.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformArea(area) {
      return AreaConvert.covertArea(area)
    },
    resetData() {
      this.changeData = []
      this.services = []
      this.service = undefined
      this.data = []
      this.result = undefined
    },
    async editAOI(feature) {
      try {
        this.loading = true
        this.editFeature = feature
        await this.changeMapMode('overlay')
        const res = await this.getFeature(feature)
        this.editing = true
        this.$nextTick(() => {
          this.$refs.map.drawAOI({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: res.geojson,
              },
            ],
          })
        })
      } catch (e) {
        this.editing = false
      } finally {
        this.loading = false
      }
    },
    async saveFeature() {
      try {
        if (this.$refs.map.getAllDrawFeature().length !== 1)
          return this.$store.commit('message/SHOW_ERROR', 'Total of features needed is 1')
        this.loading = true
        await updateFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: this.editFeature.id,
          params: {
            geometry: this.$refs.map.getAllDrawFeature()[0].geometry,
            type: this.editFeature.type,
          },
        })
        await this.getData()
      } catch (e) {
      } finally {
        this.editing = false
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
