import api from '@/api/api'
import config from '@/config'

export async function updateFeature(data) {
  return await api.put(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/features/${data.featureId}`,
    data.params,
  )
}

export async function getTypeVector(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/features/unique-property`,
    data.payload,
  )
}

export async function getVectorQAQC(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/qa-qc/vectors`, data.payload)
}

export async function getOverviewQAQC(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/qa-qc/overview`, data.payload)
}

export async function updateVectorQAQC(data) {
  return await api.put(
    `${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/vectors/${data.vectorId}`,
    data.params,
  )
}

export async function createVectorQAQC(data) {
  return await api.post(
    `${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/vectors/${data.vectorId}/features`,
    data.params,
  )
}
